import React, {useRef, useState} from 'react';
import { TextField, Button } from '@mui/material';

import firebase from './firebase.js';
import { addDoc, collection, setDoc, getDoc, query, doc } from "@firebase/firestore"
import {firestore} from './firebase.js';


function Testing() {
    const [data, setData] = useState(null);

    const ref = collection(firestore, "SomeTest")
    const docRef = doc(firestore, "SomeTest", "TestDoc")

    const nameRef = useRef(null);

    const save = async(e) => {
        e.preventDefault();

        const name = nameRef.current;

        let data = {
            name: name.value
        }
        
        try {
            addDoc(docRef, data)
            console.log(data)
        } catch(e) {
            console.log(e)
        }

    }

    function logDocs() {
        getDoc(docRef).then((docSnap) => {
                if(docSnap.exists()) {
                    const data = docSnap.data();
                    console.log(data);
                    setData(data);
                } else {
                    console.log("No such document", docSnap.data());
                }
            })
            .catch((error) => {
                console.log("Error getting document:", error);
            })
    }



    return (
        <div>
            <form className="form" onSubmit={save}>
                <div className="form-row">
                    <TextField type="text" label="Name" variant="outlined" inputRef={nameRef} />
                </div>
                <div className="form-row">
                    <Button type="submit">Submit</Button>
                </div>
            </form>

            <div>
                <Button onClick={logDocs} >Log Docs</Button>
            </div>
        </div>
    );
}

export default Testing;