// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyCbqpP2LZUw5tKs-kmNeDC7mdEz34lIm9I",
    authDomain: "wfjukebox-6bc95.firebaseapp.com",
    databaseURL: "https://wfjukebox-6bc95-default-rtdb.firebaseio.com",
    projectId: "wfjukebox-6bc95",
    storageBucket: "wfjukebox-6bc95.appspot.com",
    messagingSenderId: "562523871980",
    appId: "1:562523871980:web:b034ee11db0a7d98f923c9",
    measurementId: "G-2V4J17MKVZ"
    };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firestore = getFirestore(app);

